<template>
  <v-content>
    <v-container grid-list-lg fluid>
      <v-layout wrap align-start justify-start fill-height>
        <v-flex xs12 lg12 xl12>
          <app-title title="Ofertas del día">
            <template slot="subtitle">
              Ofertas del dia ingresadas
            </template>
          </app-title>
        </v-flex>
        <v-flex xs12 lg12 xl12>


          <v-card class="elevation-2">
            <v-data-table
              :headers="headers"
              :items="productsSaleDay"
              :pagination.sync="pagination"
              :rows-per-page-items="rowsPerPage"
              :loading="loading"
              class="elevation-0">
              <template slot="items" slot-scope="props">
                <tr @click="goProductsSaleDay(props.item.id)">
                  <td>{{ props.item.dayFormat.name }}</td>
                  <td class="text-xs-left">
                    <h2 class="subheading font-weight-light">{{ props.item.detail.name }}</h2>
                  </td>
                  <td>
                    {{ props.item.price | toPrice }}
                  </td>
                  <td class="text-xs-left">
                    <v-chip label color="success" text-color="white" v-if="props.item.status">
                      <v-icon left>check</v-icon> Activo
                    </v-chip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-btn
      fixed
      dark
      fab
      bottom
      right
      @click="addSaleDay"
      color="secondary">
      <v-icon>add</v-icon>
    </v-btn>
  </v-content>
</template>

<script>
import appTitle from '../useful/title.vue';
import { GET_API } from '../../config';

export default {
  components: { appTitle },
  name: 'Products',
  metaInfo: {
    title: 'Productos',
  },
  methods: {
    goProduct(route, id) {
      this.$router.push({ name: route, params: { id } });
    },
    getProducts() {
      this.$http({
        method: 'post',
        url: GET_API,
        params: {
          p: 'getProductsStore',
          filter: this.productsFilter,
        },
      }).then((res) => {
        console.log(res);
        this.productsAll = res.data.json;
        this.products = res.data.json;
      });
    },
    searchInputHandle() {
      const search = this.searchText;
      let result = this._.filter(this.productsAll, (item) => {
        const res = this._.includes(this._.lowerCase(item.name), this._.lowerCase(search));
        return res;
      });
      if (result.length === 0) {
        result = this._.filter(this.productsAll, (item) => {
          const res = this._.includes(this._.lowerCase(item.id), this._.lowerCase(search));
          return res;
        });
      }
      if (result.length > 0) {
        this.products = result;
      } else {
        this.products = [];
      }
      this.dialogSearch = false;
    },
    getProductsSaleDay() {
      this.$http({
        method: 'post',
        url: GET_API,
        params: {
          p: 'getProductsSaleDay',
        },
      }).then((res) => {
        console.log('getProductsSaleDay =>', res);
        this.productsSaleDay = res.data.json;
      });
    },
    addSaleDay(id) {
      this.$router.push({ name: 'productsSaleDayNew' });
    },
    goProductsSaleDay(id) {
      this.$router.push({ name: 'productsSaleDayEdit', params: { id } });
    },
  },
  data() {
    return {
      loading: false,
      productsAll: [],
      products: [],
      productsSaleDay: [],
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      productsFilter: {
        on: true,
        off: false,
        hidden: false,
      },
      searchText: '',
      headers: [
        {
          text: 'Día', align: 'left', sortable: false, value: 'photo',
        },
        {
          text: 'Producto', align: 'left', sortable: false, value: 'name',
        },
        {
          text: 'Precio', align: 'left', sortable: false, value: 'name',
        },
        {
          text: 'Activo', align: 'left', sortable: false, value: 'status',
        },
      ],
    };
  },
  mounted() {
    this.getProducts();
    this.getProductsSaleDay();
  },
};
</script>
